<template>
	<section>
		<div class="form">
			<div class="calendar">
				<datepicker
					v-model="form.date"
					:disabled-dates="getDisableDate()"
					:inline="true"
					:full-month-name="true"
					:language="calendarTH"
					calendar-class="datepicker-box-inline"
					format="dd/MM/yyyy">
				</datepicker>
			</div>
			<div class="holiday">
				<div class="operation">
					<div class="input-field">
						<input type="text"
							v-model="form.holiday"
							placeholder="ชื่อวันหยุด"
							class="square"
							:class="{ 'invalid': error.holiday }" />
					</div>
					<div class="action">
						<button class="btn btn-main btn-submit square" @click="saveHoliday()">บันทึก</button>
					</div>
				</div>

				<div class="info mt-3">
					<div class="result">
						<table>
							<thead>
								<tr>
									<th>วันที่</th>
									<th>วันหยุด</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in resultList" :key="index">
									<td>{{ item.dateText }}</td>
									<td>{{ item.name }}</td>
									<td><button class="btn-remove" @click="removeHoliday(item)"><b-icon icon="trash-fill"></b-icon></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { th } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import RouterPath from '@/router/path';

export default {
	data() {
		return {
			calendarTH: th,
			form: this.initForm(),
			error: this.initError(),
			isResultReady: false,
			resultList: []
		}
	},
	mounted() {
		this.getHoliday();
	},
	methods: {
		initForm() {
			return {
				date: '',
				holiday: ''
			};
		},
		initError() {
            return {
                date: false,
                holiday: false
            };
        },
		displayDate() {
			if (this.form.date) {
				return moment(this.form.date).format('DD/MM/YYYY');
			}

			return '';
		},
		async getHoliday() {
			this.isResultReady = false;

			const result = await SellerService.getHoliday();

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			this.resultList = result.data;
			this.isResultReady = true;
		},
		getDisableDate() {
            return { to: new Date() };
        },
		async removeHoliday(data) {
			this.$swal(
                Helper.confirmAlert('ยืนยันการลบวันหยุด', 'โปรดยืนยันว่าต้องการลบวันหยุดวันที่ ' + data.dateText + ' (' + data.name + ')')
            ).then(async (confirm) => {
				if (confirm.isConfirmed) {
					let loader = this.$modalLoader.render();
					const result = await SellerService.removeHoliday(data.dateKey);
					loader.hide();

					if (result.data?.success) {
						this.getHoliday();
					} else {
						this.$swal(Helper.warningAlert('ไม่สามารถลบวันหยุดได้', result.data?.message));
					}
				}
            });
		},
		async saveHoliday() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
                const result = await SellerService.saveHoliday(this.form);
                loader.hide();

                if (result.data?.success) {
                    this.form = this.initForm();

					this.getHoliday();
				} else {
					this.$swal(Helper.warningAlert('ไม่สามารถบันทึกวันหยุดได้', result.data?.message));
				}
            }
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            for (const property in this.error) {
                if (!this.form[property]) {
                    this.error[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ไม่สามารถบันทึกวันหยุดได้', 'โปรดใส่ข้อมูลวันหยุดให้ครบถ้วน'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.form {
	display: flex;
	flex-wrap: wrap;

	.calendar {
		width: 35%;
		padding-right: 20px;

		@media only screen and (max-width: $screenLarge) {
			width: 45%;
		}

		@media only screen and (max-width: $screenSmall) {
			width: 100%;
			max-width: 400px;
			margin: auto;
			padding-right: 0;
		}
	}

	.holiday {
		width: 65%;
		padding-left: 10px;

		@media only screen and (max-width: $screenLarge) {
			width: 55%;
		}

		@media only screen and (max-width: $screenSmall) {
			width: 100%;
			padding-left: 0;
		}

		.operation {
			display: flex;
			width: 100%;

			@media only screen and (max-width: $screenSmall) {
				max-width: 500px;
				margin: 15px auto 0;
			}

			.input-field {
				flex: 1;

				input {
					border: 1px solid #999;
					font-size: 15px;

					&[readonly] {
						background: #fff;
					}

					@media only screen and (max-width: $screenSmall) {
						font-size: 14px;
					}
				}
			}

			.btn-submit {
				padding: 5px;
				width: 75px;
				height: 40px;
				font-size: 15px;

				@media only screen and (max-width: $screenSmall) {
					font-size: 14px;
					height: 37px;
				}
			}
		}

		.info {
			.result {
				table {
					width: 100%;

					thead {
						th {
							background: #ddd;
							padding: 10px;
							font-size: 14px;
						}
					}

					tbody {
						td {
							border-bottom: 1px solid #999;
							padding: 15px 10px;
							font-size: 13px;
						}
					}
				}

				.btn-remove {
					margin: 0;
					padding: 0;
					border: none;
					background: none;
					color: #ff9900;
					font-size: 16px;
				}
			}
		}
	}
}

::v-deep .calendar {
	.vdp-datepicker {
		.cell {
			@media only screen and (max-width: $screenMedium) {
				font-size: 15px;
			}

			@media only screen and (max-width: $screenSmall) {
				font-size: 13px;
			}
		}
	}
}
</style>