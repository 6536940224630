<template>
	<div>
		<h2>กำหนดวันหยุดพิเศษร้านค้า</h2>
		<article class="mb-4">
			<p>สามารถกำหนดล่วงหน้าได้ไม่เกิน 24 ชม.</p>
			<p>ถ้าลูกค้าเลือกวันติดตั้งตรงกับวันหยุด จะมีการแจ้งเตือน "ไม่สามารถเลือกวันนี้ได้เนื่องจากร้านค้าหยุดทำการ"</p>
		</article>
		
		<seller-holiday></seller-holiday>
	</div>
</template>

<script>
import SellerHoliday from '@/components/seller/shop/Holiday';

export default {
	components: {
		SellerHoliday
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

h2 {
	font-size: 24px;
	font-family: 'Open Sans', 'Kanit';
	font-weight: bold;
	margin-bottom: 10px;
	color: #333;

	@media only screen and (max-width: $screenSmall) {
		font-size: 18px;
	}
}

article {
	color: #333;
	
	p {
		font-size: 13px;
		line-height: 20px;
		margin-bottom: 0;
	}
}
</style>